import styled from '@emotion/styled';
import close from '../../assets/image/close.svg';

export const AccordionButton = styled.div`
  background: url(${close}) center center no-repeat;
  background-size: 100%;
  width: 1.125rem;
  height: 1.125rem;
  position: relative;
  top: 10px;
  transform: ${(props) =>
    props.open
      ? 'translateY(-50%) rotate(0deg)'
      : 'translateY(-50%) rotate(135deg)'};
  transition: 0.2s linear;
`;
