/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from 'polished';
import arrow from '../../assets/image/prysm/arrow.svg';

const Title = styled.div`
  font-size: ${rem(42)};
  font-weight: bold;
  color: #333;
  letter-spacing: ${rem(-1.8)};
  line-height: ${rem(36)};
  margin-bottom: ${rem(12)};
  text-align: left;
`;

const Description = styled.p`
  font-size: ${rem(14)};
  color: #333;
  letter-spacing: ${rem(-0.6)};
  line-height: ${rem(12)};
  font-weight: bold;
  text-align: left;
`;

const ResultsText = styled.div`
  position: relative;
  top: ${rem(75)};
  left: 42.5%;
  max-width: ${rem(150)};
`;

const ResultsItem = styled.li`
  width: 25%;
  position: relative;
  text-align: center;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: center center;
  min-height: ${rem(160)};
  max-width: ${rem(200)};

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.xs}) {
      width: 100%;
      margin-bottom: 2rem;
    }
  `}
`;

export const ResultArrow = ({ title, description }) => (
  <ResultsItem>
    <ResultsText>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </ResultsText>
  </ResultsItem>
);
