import React from 'react';
import styled from '@emotion/styled';
import { rem } from 'polished';

const TitleSection = styled.div`
  background-color: #bebab7;
  padding: ${rem(25)} ${rem(50)} 0 ${rem(50)};
  margin-top: ${rem(128)};
  margin-bottom: ${rem(64)};
`;

const Title = styled.h3`
  font-size: ${rem(24)};
  background-color: white;
  padding: ${rem(30)} ${rem(20)} 0 ${rem(20)};
  position: relative;
  top: ${rem(10)};
`;

export const SectionTitle = ({ children, ...props }) => (
  <TitleSection {...props}>
    <Title>{children}</Title>
  </TitleSection>
);
