import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import ScrollToTop from './components/ScrollToTop';
import { theme } from './theme';
import { App } from './App';
import './assets/scss/main.scss';
import './index.scss';

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
