const breakpoints = {
  xs: '567px',
  sm: '768px',
  md: '992px',
  lg: '1100px',
  xl: '1500px',
};

const media = {
  xs: `@media screen and (max-width: ${breakpoints.xs})`, // 567
  sm: `@media screen and (max-width: ${breakpoints.sm})`, // 768
  md: `@media screen and (max-width: ${breakpoints.md})`, // 992
  lg: `@media screen and (max-width: ${breakpoints.lg})`, // 1100
  xl: `@media screen and (max-width: ${breakpoints.xl})`, // 1500
};

export const theme = {
  breakpoints,
  media,
};
