import styled from '@emotion/styled';
import { rem } from 'polished';

export const SectionText = styled.p`
  padding-left: ${rem(50)};
  padding-right: ${rem(50)};
  margin-bottom: ${rem(50)};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  font-size: ${rem(20)};
  color: #333;
`;
