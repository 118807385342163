/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { ResultList } from '.';

export const Results = (props) => {
  const ResultsTitle = styled.h4`
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 2.5rem;
    position: relative;
    font-size: 1rem;
    color: #333;
    letter-spacing: 0.13125rem;
    line-height: 2.4375rem;
    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 40%;
      width: 80%;
      height: 0.0625rem;
      background: #d8d8d8;
      left: 10%;
      z-index: -1;
    }

    span {
      background: white;
      padding: 0 1rem;
      font-size: 1rem;
      color: #333;
      letter-spacing: 0.0625rem;
      line-height: 1.75rem;
    }

    ${({ theme }) => css`
      @media (max-width: ${theme.breakpoints.sm}) {
        line-height: 1.4rem;
        &:after,
        &:before {
          display: none;
        }
      }
    `}
  `;

  return (
    <section className="container" css={{ marginTop: rem(72) }} {...props}>
      <ResultsTitle>
        <span>12 Month Post-Implementation Results</span>
      </ResultsTitle>
      <ResultList />
    </section>
  );
};
