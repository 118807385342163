import React from 'react';
import Img from 'react-image';
import step1 from '../../assets/image/prysm/Step1.png';
import step2 from '../../assets/image/prysm/Step2.png';
import step3 from '../../assets/image/prysm/Step3.png';
import step4 from '../../assets/image/prysm/Step4.png';
import step5 from '../../assets/image/prysm/Step5.png';
import { Accordion, AccordionItem } from '../../components';

export const PrysmMobileAccordion = () => (
  <Accordion>
    <AccordionItem title="Step 1" subtitle="Generate Patient List">
      <h3>
        Single click reporting and automated encounter analysis provides
        fundraisers with real time access to patients with greatest affinity and
        capacity.
      </h3>
      <Img src={step1} />
    </AccordionItem>
    <AccordionItem title="Step 2" subtitle="Review with clinicians">
      <h3>
        When clinicians are engaged in a process with analytically sound
        information and consistent follow up reporting, they are highly willing
        to partner with development.
      </h3>
      <Img src={step2} />
    </AccordionItem>
    <AccordionItem title="Step 3" subtitle="Contact Patients">
      <h3>
        Intuitive in-platform phone call and email tracking enables fundraisers
        to efficiently record discovery activity.
      </h3>
      <Img src={step3} />
    </AccordionItem>
    <AccordionItem title="Step 4" subtitle="Report Outcomes">
      <h3>
        Intuitive single click reporting enables fundraisers to view clinicians’
        patients using a scoring model that combines affinity and capacity.
      </h3>
      <Img src={step4} />
    </AccordionItem>
    <AccordionItem title="Step 5" subtitle="Optimize Strategy">
      <h3>
        Easily track and record outcomes of discovery activity to optimize
        program strategy.
      </h3>
      <Img src={step5} />
    </AccordionItem>
  </Accordion>
);
