/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Accordion, AccordionItem } from '../../components';
import Img from 'react-image';

import fundraisers from '../../assets/image/home/whyPrysm/fundraisers.png';
import development from '../../assets/image/home/whyPrysm/development.png';
import management from '../../assets/image/home/whyPrysm/management.png';
import discovery from '../../assets/image/home/whyPrysm/discovery.png';
import annual from '../../assets/image/home/whyPrysm/annual.png';
import vip from '../../assets/image/home/whyPrysm/vip.png';

import { ReactComponent as Analyze } from '../../assets/image/home/whyPrysm/analyze.svg';
import { ReactComponent as Security } from '../../assets/image/home/whyPrysm/security.svg';
import { ReactComponent as Integration } from '../../assets/image/home/whyPrysm/integration.svg';
import { ReactComponent as Clock } from '../../assets/image/home/whyPrysm/clock.svg';

export const HomeMobileAccordion = () => (
  <Accordion>
    <AccordionItem title="Frontline Fundraisers">
      <h3>Why Prysm</h3>
      <h4>
        Increased qualification activity = increased philanthropic revenue. It’s
        that simple.
      </h4>
      <h5>Major Giving</h5>
      <p>
        Prysm removes friction from fundraising workflows by delivering
        on-demand information and a streamlined process for clinician engagement
        and patient outreach. This leads to an exponential increase in discovery
        visits with patients.
      </p>
      <Img src={fundraisers} />
    </AccordionItem>
    <AccordionItem title="Development Executives">
      <h3>Why Prysm</h3>
      <h4>
        Increased qualification activity = increased philanthropic revenue. It’s
        that simple.
      </h4>
      <p>
        By insourcing a quantifiable process for patient outreach and grateful
        patient identification, your 501(c3) will establish an internal
        knowledgebase about the unique characteristics that drive grateful
        patient philanthropy in your health system.
      </p>
      <ul>
        <li>
          Enable your fundraisers to be more productive with an intuitive
          patient outreach application that’s easy to use.
        </li>
        <li>
          Engage stakeholders in dynamic, evidence based conversations about
          patient philanthropy.
        </li>
      </ul>
      <h4>Focus on the metrics that matter.</h4>
      <Img src={development} />
    </AccordionItem>
    <AccordionItem title="Project Management">
      <h3>Why Prysm</h3>
      <h4>
        Increased qualification activity = increased philanthropic revenue. It’s
        that simple.
      </h4>
      <h5>Prospect Management</h5>
      <p>
        Prysm provides prospect managers with a suite of tools to identify
        grateful patient opportunities. Integrated workflows enable prospect
        managers to collaborate seamlessly with frontline fundraisers.
      </p>
      <p>
        Embedded encounter analysis and native integration with our wealth
        screening and machine learning partners transforms prospect management’s
        role in grateful patient philanthropy.
      </p>
      <Img src={management} />
    </AccordionItem>
    <AccordionItem title="IT and Database Management">
      <h3>Why Prysm</h3>
      <h4>
        Increased qualification activity = increased philanthropic revenue. It’s
        that simple.
      </h4>
      <p>
        Prysm integrates with your health system’s EMR and Donor Management
        System.
      </p>
      <p>
        By introducing an advanced HIPAA compliant component to your grateful
        patient program, your donor management system is able to perform as
        intended and your fundraising strategy becomes more flexible. Manage
        your PHI and related applications in one secure system.
      </p>
      <ul
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;

          li {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            width: 48%;
            margin-bottom: 1rem;
            img {
              width: 1.4375rem;
              margin-right: 0.5rem;
            }
            .databaseDescription {
              span {
                display: block;
              }
            }
          }
        `}
      >
        <li>
          <Security />
          <div className="databaseDescription">
            <h5>Security</h5>
            <span>HIPAA compliant</span>
            <span>single sign on (SS0)</span>
          </div>
        </li>
        <li>
          <Integration />
          <div className="databaseDescription">
            <h5>Security</h5>
            <span>HIPAA compliant</span>
            <span>single sign on (SS0)</span>
          </div>
        </li>
        <li>
          <Clock />
          <div className="databaseDescription">
            <h5>Security</h5>
            <span>HIPAA compliant</span>
            <span>single sign on (SS0)</span>
          </div>
        </li>
        <li>
          <Analyze />
          <div className="databaseDescription">
            <h5>Security</h5>
            <span>HIPAA compliant</span>
            <span>single sign on (SS0)</span>
          </div>
        </li>
      </ul>
    </AccordionItem>
    <AccordionItem title="Discovery Visit Scheduling">
      <h3>Why Prysm</h3>
      <h4>
        Increased qualification activity = increased philanthropic revenue. It’s
        that simple.
      </h4>
      <p>
        Increase activity and number of patient discovery visits using
        in-platform calling lists and queues through Prysm’s native integration
        with TalkDesk. Automate activity tracking, recording and reporting to
        contact more patients with less effort.
      </p>
      <Img src={discovery} />
    </AccordionItem>
    <AccordionItem title="Annual Giving">
      <h3>Why Prysm</h3>
      <h4>
        Increased qualification activity = increased philanthropic revenue. It’s
        that simple.
      </h4>
      <p>
        Hyper segmented affinity and capacity modeling enables organizations to
        develop personalized communications to patients based on individual
        affinity to specific departments and clinicians.
      </p>
      <Img src={annual} />
    </AccordionItem>
    <AccordionItem title="VIP Concierge">
      <h3>Why Prysm</h3>
      <h4>
        Increased qualification activity = increased philanthropic revenue. It’s
        that simple.
      </h4>
      <p>
        Integrate your major gift fundraising strategy with your vip/concierge
        services for frictionless communication that drives fundraising
        performance. Ensure your team stays in the know about patients with the
        greatest affinity for your organization.
      </p>
      <p>Choreograph service excellence across your health system.</p>
      <Img src={vip} className="vip" />
    </AccordionItem>
  </Accordion>
);
