import React from 'react';
import styles from './Prysm.module.scss';
import { SectionTitle, SectionText } from '.';

const sectionText = {
  title: 'Fuel your major gift pipeline with a dedicated healthcare CRM.',
  text: `Provide your non-profit healthcare organization with a process to
    supercharge Major, Mid-level and Annual Giving pipeline growth +
    Patient Experience.`,
  emphasis: `Combining sound information with a sustainable process allows
    organizations to optimize fundraising strategy.`,
};

export const GiftPipeline = (props) => (
  <section className={`${styles.major} container`} {...props}>
    <h3>{sectionText.title}</h3>
    <div className={styles.majorDescription}>
      <p>{sectionText.text}</p>
      <p css={{ fontWeight: 'bold' }}>{sectionText.emphasis}</p>
    </div>
  </section>
);

export const GiftPipelineMobile = (props) => (
  <div>
    <SectionTitle>{sectionText.title}</SectionTitle>
    <SectionText>{sectionText.text}</SectionText>
    <SectionText bold>{sectionText.emphasis}</SectionText>
  </div>
);
