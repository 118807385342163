/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState } from 'react';
import { rem } from 'polished';
import {
  Banner,
  HowItWorks,
  PrysmSlider,
  PrysmTabs,
  PrysmAmountSlider,
} from '../../components';
import { CaseStudy, GiftPipeline, GiftPipelineMobile, Results } from '.';
import Img from 'react-image';

import { PrysmMobileAccordion } from './PrysmMobileAccordion';
import { Text } from '../../Primitives';
import styles from './Prysm.module.scss';
import banner from '../../assets/image/prysm/BannerPrysm.jpg';
import logo from '../../assets/image/logo.svg';
import integrations from '../../assets/image/prysm/integrations.webp';
import analyze from '../../assets/image/prysm/analyze.webp';
import moreTime from '../../assets/image/prysm/phone.jpg';

import { ReactComponent as Windfall } from '../../assets/image/partners/windfall.svg';
import { ReactComponent as Telkdesk } from '../../assets/image/partners/telkdesk.svg';
import { ReactComponent as We } from '../../assets/image/partners/we.svg';
import { ReactComponent as Geopointe } from '../../assets/image/partners/geopointe.svg';

import notebook1 from '../../assets/image/prysm/notebook.jpg';
import notebook2 from '../../assets/image/prysm/notebook_graph.jpg';
import notebook3 from '../../assets/image/prysm/dash.jpg';
import notebook4 from '../../assets/image/prysm/doppler.jpg';

export const Prysm = ({ setModal }) => {
  const isClient = typeof window === 'object';
  const [windowSize, setWindowSize] = useState(getSize);

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  useEffect(() => {
    if (!isClient) return false;

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient]); // eslint-disable-line

  return (
    <div className={styles.prysm}>
      <Banner
        logo={logo}
        image={banner}
        text="How it works"
        windowSize={windowSize}
        forceLogo={true}
      />
      <div className={`${styles.provide} container`}>
        <h3
          css={(theme) => css`
            ${theme.media.xs} {
              text-align: left;
              padding: 25px !important;
            }
          `}
        >
          Prysm provides an enterprise wide process supported by technology to
          drive clinician engagement and grateful patient identification at
          scale.
        </h3>
        <Text css={{ textAlign: 'center' }}>
          Drive major gift identification with five intuitive, repeatable steps
          to support sustainable clinician engagement.
        </Text>
      </div>
      {windowSize.width > 767 ? <PrysmTabs /> : <PrysmMobileAccordion />}
      <section className={`${styles.grateful} container`}>
        <h3>Prysm Features</h3>
        <PrysmSlider windowSize={windowSize} />
      </section>
      <section className={`${styles.integrates} container`}>
        <h3>
          Prysm integrates seamlessly with your health system’s EMR and Donor
          Management System.
        </h3>
        <Img
          className={styles.integrations}
          src={integrations}
          alt="integrations"
        />
      </section>
      <div
        css={(theme) => css`
          @media (max-width: ${theme.breakpoints.sm}) {
            display: none;
          }
        `}
      >
        <GiftPipeline />
      </div>

      <Results
        css={(theme) => css`
          @media (max-width: ${theme.breakpoints.sm}) {
            display: none;
          }
        `}
      />

      <section className={`${styles.analyze} container`}>
        <Img className={styles.analyzemonitor} src={analyze} alt="analyze" />
        <h3>
          Analyze program growth and performance in real time to identify
          opportunities and maximize results.
        </h3>
      </section>
      <section className={styles.partners}>
        <div className="container">
          <ul className={styles.partnersList}>
            <li>
              <Img
                className={styles.partnerImage}
                src={moreTime}
                alt="more time"
              />
              <div className={styles.partnersDescription}>
                <h3>What would you do with more time?</h3>
                <p>
                  Prysm saves fundraisers’ time by delivering on-demand
                  information and a streamlined process for patient outreach.
                  This leads to an exponential increase in discovery visits with
                  patients.
                </p>
                <p>
                  Increased activity = increased philanthropic revenue. It’s
                  really that simple.
                </p>
              </div>
            </li>
            <li>
              <Img
                className={styles.partnerImage}
                src={notebook1}
                alt="notebook"
              />
              <div className={styles.partnersDescription}>
                <h3>
                  <Windfall alt="Windfall" />
                </h3>
                <p>
                  Windfall is changing the way that organizations identify,
                  understand, and engage the affluent. Through next generation
                  API technology, Windfall’s data science and machine learning
                  scientists are able to seamlessly collaborate with Prysm
                  clients.
                </p>
                <div className={styles.partnerRate}>
                  <div className={styles.partnerRateItem}>
                    <div>4x</div>
                    <span>Lead conversion of principal gift prospects.</span>
                  </div>
                  <div className={styles.partnerRateItem}>
                    <div>9.6%</div>
                    <span>Increase in grateful patient identification.</span>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Img
                className={styles.partnerImage}
                src={notebook2}
                alt="notebook"
              />
              <div className={styles.partnersDescription}>
                <h3>
                  <Telkdesk alt="Telkdesk" />
                </h3>
                <p>
                  Through an innovation partnership with TalkDesk, Prysm
                  provides a seamless discovery visit scheduling process that
                  drives transformational patient outreach.
                </p>
              </div>
            </li>
            <li>
              <Img
                className={styles.partnerImage}
                src={notebook3}
                alt="notebook3"
              />
              <div className={styles.partnersDescription}>
                <h3>
                  <We alt="We" />
                </h3>
                <p>
                  By combining WealthEngine’s proven wealth screening technology
                  with real-time patient encounter analysis and integrated AI,
                  Prysm transforms development’s ability to engage clinicians,
                  nurses, and patients.
                </p>
              </div>
            </li>
            <li>
              <Img
                className={styles.partnerImage}
                src={notebook4}
                alt="notebook"
              />
              <div className={`${styles.partnersDescription} ${styles.direct}`}>
                <h3>
                  <Geopointe alt="Geopointe" width={108.8} />
                </h3>
                <p>
                  Gain deep insight into your patient population with Geopointe.
                </p>
                <p>
                  Redefine your grateful patient program strategy using the
                  latest technology in visual mapping. Ultra segmentation of
                  patients for:
                </p>
                <ul className={styles.partnerSubList}>
                  <li>Major Giving</li>
                  <li>Direct Mail</li>
                  <li>Digital Marketing</li>
                  <li>Events</li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <div>
        <CaseStudy />
      </div>

      <div
        css={(theme) => css`
          @media (min-width: ${theme.breakpoints.sm}) {
            display: none;
          }
        `}
      >
        <GiftPipelineMobile />
      </div>

      <Results
        css={(theme) => css`
          @media (min-width: ${theme.breakpoints.sm}) {
            display: none;
          }
        `}
      />

      <section className={styles.amount}>
        <div className="container">
          <h3>Average $ Amount Raised per Major Gift Officer</h3>
          <div className={styles.amountInner}>
            <PrysmAmountSlider />
          </div>
        </div>
      </section>
      <section
        css={(theme) => css`
          width: 100%;
          margin-left: auto;
          margin-right: auto;

          ${theme.media.sm} {
            padding-bottom: ${rem(96)};
          }
        `}
      >
        <blockquote>
          It’s exciting to finally have a technology that supports a
          clinician-led referral-based grateful patient strategy which two
          decades of research shows is the core of successful major gift
          fundraising in healthcare. Prysm maintains the integrity of the
          clinician-patient relationship we have long recognized as the primary
          driver of the gratitude driven referral model. This is the most
          elegant solution we’ve seen to operationalize grateful patient
          programming and maximize the effectiveness of front line fundraisers.
          <span>Ben Golding, Advancement Resources</span>
        </blockquote>
      </section>
      <HowItWorks setModal={setModal} />
    </div>
  );
};
