/** @jsx jsx */
import { jsx } from '@emotion/core';
import styles from './Thankyou.module.scss';

export const Thankyou = ({ setModal }) => {
  return (
    <div className={styles.thankyou}>
      <p>Form submitted. Thank you!</p>
    </div>
  );
};
