import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from 'polished';

import { ReactComponent as Cetrix } from '../../assets/image/partners/cetrix.svg';
import { ReactComponent as Geopointe } from '../../assets/image/partners/geopointe.svg';
import { ReactComponent as Telkdesk } from '../../assets/image/partners/telkdesk.svg';
import { ReactComponent as We } from '../../assets/image/partners/we.svg';
import { ReactComponent as Windfall } from '../../assets/image/partners/windfall.svg';

const PartnersSection = styled.section`
  ${({ theme }) => css`
    ${theme.media.sm} {
      overflow: auto;
      padding-bottom: ${rem(96)};
    }
  `}
`;

const PartnersInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 61%;
  margin: 0 auto;
  padding: 3.6rem 0 3.1rem 0;

  img {
    max-height: 4.125rem;
    max-width: 18%;
  }

  ${({ theme }) => css`
    ${theme.media.sm} {
      min-width: 40rem;
    }
  `}
`;

export const Partners = () => (
  <PartnersSection>
    <PartnersInner>
      <Cetrix height={50} />
      <Geopointe height={50} />
      <We height={50} />
      <Windfall height={50} />
      <Telkdesk height={50} />
    </PartnersInner>
  </PartnersSection>
);
