import React from 'react';
import styled from '@emotion/styled';
import { ResultArrow } from './ResultArrow';

const ResultListWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
`;

export const ResultList = (props) => (
  <ResultListWrapper {...props}>
    <ResultArrow title="425%" description="Introductory Phone Calls" />
    <ResultArrow title="475%" description="Qualification Meetings" />
    <ResultArrow title="1,200%" description="Qualified Patients" />
    <ResultArrow
      title="$1.8M"
      description="First Time Grateful Patient Donations (FY18)"
    />
  </ResultListWrapper>
);
