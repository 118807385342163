import React from 'react';
import styles from './Modal.module.scss';

export const Modal = ({ modal, setModal }) => {
  const closeModal = () => {
    setModal(!modal);
  };

  return (
    modal && (
      <div className={styles.modalWrapper}>
        <div className={styles.modal}>
          <span className={styles.close} onClick={() => closeModal()}>
            x
          </span>
          <h3>Contact Us</h3>
          <div className={styles.modalInner}>
            {/* Add the title attribute without the comment */}
            <div className="contact">
              <form name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/#/thankyou">
                <div className={styles.modalInput}>
                  <label htmlFor="firstname">First Name</label>
                  <input type="text" pattern="[A-Za-z ]{1,32}" title="Enter a valid Firstname" name="firstname" size="30" maxLength="40" required />
                </div>

                <div className={styles.modalInput}>
                  <label htmlFor="lastname">Last Name</label>
                  <input type="text" pattern="[A-Za-z]{1,32}" title="Enter a valid Lastname" name="lastname" size="30" maxLength="80" required />
                </div>

                <div className={styles.modalInput}>
                  <label htmlFor="organization">Organization/Company</label>
                  <input type="text" name="organization" size="30" maxLength="255" />
                </div>

                <div className={styles.modalInput}>
                  <label htmlFor="email">Email *</label>
                  <input type="email" name="email" size="30" maxLength="255" required />
                </div>

                <div className={styles.modalInput}>
                  <label htmlFor="phone">Phone</label>
                  <input type="text" pattern = "[0-9]+([-\,][0-9]+)?" title='Enter a valid Phone Number' name="phone" maxLength="14" required />
                </div>

                <div className={styles.modalInput}>
                  <label htmlFor="comments">Comments</label>
                  <textarea name="comments" cols="40" rows="3"></textarea>
                </div>

                <div className={styles.modalInput}>
                  <input type="submit" name="submit" value="Submit" />
                </div>

                <input type="hidden" name="form-name" value="contact" />
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};
