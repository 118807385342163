import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from 'polished';

export const SectionTitle = styled.h2`
  font-weight: 100;
  font-size: 2.75rem;
  line-height: 1.4;

  ${({ theme }) => css`
    ${theme.media.xs} {
      font-size: ${rem(24)};

      br {
        display: none;
      }
    }
  `}
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: ${rem(24)};
  color: #7e7670;
  letter-spacing: ${rem(0.03)};
  line-height: ${rem(28)};
  margin-bottom: ${rem(20)};
`;
