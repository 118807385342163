/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { rem } from 'polished';
import Img from 'react-image';
import styles from './Prysm.module.scss';
import total from '../../assets/image/prysm/total.webp';
import { SectionTitle, SectionText } from '.';

const hideMobileStyles = (theme) => css`
  @media (max-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`;

const hideDesktopStyles = (theme) => css`
  @media (min-width: ${theme.breakpoints.sm}) {
    display: none;
  }
`;

const CaseStudyDesktop = (props) => (
  <div className={styles.caseDescription}>
    <h3 css={hideMobileStyles}>
      Case Study: Major <br />
      Academic Medical Center
    </h3>
    <br />
    <br />
    <p>
      This academic medical center had no prior history of grateful patient
      philanthropy, flat wealth screening results, low physician engagement,
      multiple health system re-brandings and a 100% outpatient population.
      Whoa! That’s a mouthful.
    </p>
    <p>
      Leveraging Prysm, 2 talented major gift officers partnered with 2
      enthusiastic development associates to transform healthcare philanthropy
      at their organization. Over a 12 month period, they expanded their major
      gift pipeline with 41 new qualified major gift prospects and 8 first-time
      donations totaling $1.8M.
    </p>
  </div>
);

const CaseStudyMobile = (props) => (
  <div>
    <SectionTitle css={{ marginTop: rem(40) }}>
      Case Study: Major <br />
      Academic Medical Center
    </SectionTitle>
    <SectionText>
      This academic medical center had no prior history of grateful patient
      philanthropy, flat wealth screening results, low physician engagement,
      multiple health system re-brandings and a 100% outpatient population.
      Whoa! That’s a mouthful.
    </SectionText>
    <SectionText>
      Leveraging Prysm, 2 talented major gift officers partnered with 2
      enthusiastic development associates to transform healthcare philanthropy
      at their organization. Over a 12 month period, they expanded their major
      gift pipeline with 41 new qualified major gift prospects and 8 first-time
      donations totaling $1.8M.
    </SectionText>
  </div>
);

export const CaseStudy = (props) => (
  <section className={`${styles.case} container`}>
    <div css={hideMobileStyles}>
      <CaseStudyDesktop />
    </div>
    <div css={hideDesktopStyles}>
      <CaseStudyMobile />
    </div>
    <Img className={styles.total} src={total} alt="total" />
  </section>
);
