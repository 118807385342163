/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useState } from 'react';
import { rem } from 'polished';
import { AccordionButton } from './AccordionButton';

const AccordionTitle = styled.p`
  color: ${(props) => (props.open ? '#1577d9' : 'inherit')};
  font-weight: ${(props) => (props.open ? 'bold' : 'normal')};
  font-size: ${rem(14)};
  line-height: 1.5;
  margin: 0;
`;

export const AccordionItem = ({ title, subtitle, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <li
      css={css`
        font-size: 0.875rem;
        letter-spacing: 0.001875rem;
        line-height: 1.8125rem;
        width: 100%;
        font-weight: 400;
        border-top: 1px solid #bebab7;
        padding: 1rem 2rem;
        position: relative;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
        `}
        onClick={() => setOpen(!open)}
      >
        <AccordionTitle open={open}>
          {title}
          {subtitle && (
            <React.Fragment>
              <br />
              {subtitle}
            </React.Fragment>
          )}
        </AccordionTitle>
        <AccordionButton open={open} />
      </div>

      {open && <div>{children}</div>}
    </li>
  );
};

export const Accordion = styled.ul`
  margin-top: 40px;
  list-style-type: none;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  color: #89817b;
`;
