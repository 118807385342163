import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { rem } from 'polished';
import { Container } from '../../Primitives';
import upper from '../../assets/image/upper-gradient.svg';
import lower from '../../assets/image/lower-gradient.svg';

const hideMobile = (theme) => css`
  ${theme.media.lg} {
    display: none;
  }
`;

const UpperImage = styled.img`
  position: absolute;
  top: 0;
  width: 100vw;

  ${(props) => hideMobile(props.theme)}
`;

const BottomImage = styled.img`
  position: absolute;
  bottom: -2.2rem;
  width: 100vw;

  ${(props) => hideMobile(props.theme)}
`;

const Logo = styled.img`
  position: absolute;
  bottom: 28%;
  left: 3%;
  width: 9.5rem;
  padding-bottom: 1em;

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      left: 4rem;
    }
  `}

  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.xs}) {
      bottom: 18%;
    }
  `}
`;

const CoverImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 55vh;
`;

const BannerContainer = styled(Container)`
  width: 100%;
  position: relative;
  min-height: 50vh;

  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.xs}) {
      height: 25vh;
    }
  `}
`;

const BannerTitle = styled.h1`
  position: absolute;
  bottom: 15.7%;
  left: 4.8%;
  letter-spacing: -0.025rem;

  ${({ theme, forceLogo }) => css`
    @media screen and (max-width: ${theme.breakpoints.xs}) {
      left: 9.8%;
      bottom: ${forceLogo ? '8%' : '28%'};
      font-size: ${rem(40)};
      width: 60%;
    }
  `}

  ${({ theme }) => css`
    @media screen and (min-width: ${theme.breakpoints.xl}) {
      left: 6.5rem;
    }
  `}
`;

export const Banner = ({
  logo = false,
  image,
  text,
  windowSize = false,
  imageStyles = {},
  forceLogo = false,
}) => {
  return (
    <BannerContainer>
      <UpperImage src={upper} alt="lower" />
      <BottomImage src={lower} alt="upper" />
      <CoverImage src={image} alt="banner" style={imageStyles} />

      {(windowSize.width > 767 || forceLogo) && logo && (
        <Logo src={logo} alt="logo" />
      )}

      <BannerTitle forceLogo={forceLogo}>{text}</BannerTitle>
    </BannerContainer>
  );
};
